<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="modal == false">
        <div class="search-body" style="height: 50px">
          <a-button type="danger" style="margin-left: 10px" @click="createnew">新建</a-button>
          <a-button style="float: right" @click="gotoList" type="default">差旅费报销列表</a-button>
        </div>
      </div>

      <div :style="{
        padding: '10px',
        width: info == null ? '90%' : '100%',
        margin: '0px auto',
      }">
        <a-form-model ref="loanbillform" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <table style="width: 100%">
            <tr>
              <td colspan="3" style="
                  font-size: 40px;
                  text-align: center;
                  padding-bottom: 20px;
                ">
                差旅费报销单
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="报销人" prop="applyUser">
                  <a-input v-model="form.applyUserName" disabled />
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="职务">
                  <a-select v-model="form.duties" placeholder="请选择">
                    <a-select-option :value="tag.name" v-for="tag in roleAll" :key="tag.id">
                      {{ tag.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="报销日期" prop="time">
                  <a-date-picker v-model="form.time" valueFormat="YYYY-MM-DD" />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="单位" prop="org">
                  <a-cascader :options="orgs" style="width: 280px" v-model="form.orgId" @change="orgChanged"
                    :fieldNames="{
                      label: 'name',
                      value: 'id',
                      children: 'children',
                    }" placeholder="请选择部室" />
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="编号" prop="number">
                  <a-input placeholder="请输入编号" v-model="form.number"></a-input>
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" label="其他报销人">
                  <MultiSearchUser :checkUsers="selUser" @callback="selectUsersCallback" />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 10px; border: none; padding-bottom: 30px">
                <a-table bordered rowKey="id" :columns="columns" :data-source="form.items" :pagination="false"
                  :locale="{ emptyText: '暂无数据' }">
                  <span slot="textbox" slot-scope="text, record, index, column">
                    <a-input v-model="record[column.dataIndex]" />
                  </span>
                  <span slot="number" slot-scope="text, record, index, column">
                    <a-input-number v-model="record[column.dataIndex]" :min="0" />
                  </span>
                  <span slot="price" slot-scope="text, record, index, column">
                    <a-input-number v-model="record[column.dataIndex]" :min="0" :precision="2" />
                  </span>
                  <span slot="time" slot-scope="text, record, index, column">
                    <a-date-picker v-model="record[column.dataIndex]" valueFormat="YYYY-MM-DD" />
                  </span>
                  <span slot="action" slot-scope="text, row, index">
                    <a-button @click="removeItem(index)" size="small" type="dashed">删除</a-button>
                  </span>
                  <template slot="footer">
                    <div style="height: 30px">
                      <a-button type="dashed" style="float: left" @click="addItem">
                        <a-icon type="plus" />
                        新增</a-button>
                    </div>
                  </template>
                </a-table>
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="附件张数：" prop="fileCount">
                  <a-input v-model="form.fileCount" style="width: 160px" />
                  <a-upload accept="image/jpeg,image/png,application/pdf" :action="uploadFileUrl" :fileList="fileList"
                    @change="handleFileChange" :remove="upLoadRemove">
                    <div v-if="form.status == 0 || form.status == 3">
                      <a-button> <a-icon type="upload" />上传文件 </a-button>
                    </div>
                  </a-upload>
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="车船机票(元)">
                  <a-input-number style="width: 80%" placeholder="请输入金额" @change="caltotal" v-model="form.ccjp"
                    :precision="2"></a-input-number>
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="伙食补助(元)">
                  <a-input-number style="width: 80%" placeholder="请输入金额" @change="caltotal" v-model="form.hsbz"
                    :precision="2"></a-input-number>
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="公杂费(元)">
                  <a-input-number style="width: 80%" placeholder="请输入金额" @change="caltotal" v-model="form.gzf"
                    :precision="2"></a-input-number>
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="住宿费(元)">
                  <a-input-number style="width: 80%" placeholder="请输入金额" @change="caltotal" v-model="form.zsf"
                    :precision="2"></a-input-number>
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="市内交通(元)">
                  <a-input-number style="width: 80%" placeholder="请输入金额" @change="caltotal" v-model="form.snjt"
                    :precision="2"></a-input-number>
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="其他(元)">
                  <a-input-number style="width: 80%" @change="caltotal" placeholder="请输入金额" v-model="form.qita"
                    :precision="2"></a-input-number>
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="金额合计(元)" prop="heji">
                  <a-input-number style="width: 80%" :precision="2" v-model="form.heji"></a-input-number>
                </a-form-model-item>
              </td>
            </tr>
          </table>
          <a-row style="text-align: center; margin-top: 20px">
            <a-col>
              <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 5px"
                @click="handleSubmit(0)">保存</a-button>
              <a-popconfirm v-if="form.status == 0 || form.status == 3" title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)">
                <a-button style="margin-right: 5px" type="danger">提交</a-button>
              </a-popconfirm>
              <a-button type="primary" v-if="form.processExecuteStatus" style="margin-right: 5px"
                @click="showaudit()">审批</a-button>
              <a-button type="default" v-if="form.status == 2" @click="showWps" style="margin-right: 5px">打印</a-button>
              <a-button @click="cancelClick">取消</a-button>
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose
          :maskClosable="false">
          <audit :info="auditModal.info" @callback="auditCallBack" />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
  import travelexpenseApi from "@/api/work/travelexpense";
  import orgApi from "@/api/org";
  import MultiSearchUser from "../components/MultiSearchUser.vue";
  import dictionaryItemApi from "@/api/system/dictionaryItem";
  import roleApi from "@/api/role";
  import pinyin from "js-pinyin";
  import request from "@/request";
  import { min } from "moment";
  const columns = [
    {
      key: "index",
      align: "center",
      title: "序号",
      width: 70,
      customRender: (text, record, index) => {
        return index + 1;
      },
    },
    {
      dataIndex: "time",
      align: "center",
      title: "年月日",
      scopedSlots: { customRender: "time" },
    },
    {
      dataIndex: "numberOfPeople",
      align: "center",
      title: "人数",
      scopedSlots: { customRender: "number" },
    },
    {
      dataIndex: "fromPosition",
      align: "center",
      title: "发站",
      scopedSlots: { customRender: "textbox" },
    },
    {
      dataIndex: "toPosition",
      align: "center",
      title: "到站",
      scopedSlots: { customRender: "textbox" },
    },
    {
      dataIndex: "price",
      align: "center",
      title: "票价",
      scopedSlots: { customRender: "price" },
    },
    {
      dataIndex: "description",
      align: "center",
      title: "事由",
      scopedSlots: { customRender: "textbox" },
    },
    {
      dataIndex: "remark",
      align: "center",
      title: "必要说明",
      scopedSlots: { customRender: "textbox" },
    },

    {
      title: "操作",
      key: "action",
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];
  export default {
    name: "travelexpense",
    data() {
      return {
        form: {
          status: 0,
          time: null,
          items: [{}],
        },
        selUser: [],
        zhiwu: [],
        roleAll: [],
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
        orgs: [],
        loading: false,
        auditModal: {
          info: null,
          visible: false,
        },
        columns,
        plainOptions: ["现金", "支票", "汇款", "其他"],
        uploadFileUrl: "",
        fileList: [],
        rules: {
          applyUser: [
            {
              required: true,
              message: "请选择报销人",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (this.form.applyUser == null || this.form.applyUser == "") {
                  callback(new Error(""));
                  return;
                }
                callback();
              },
            },
          ],
          org: [
            {
              required: true,
              message: "请选择部室",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (this.form.orgId.length == 0) {
                  callback(new Error(""));
                  return;
                }
                callback();
              },
            },
          ],
          time: [
            {
              required: true,
              message: "请选择时间",
              trigger: "change",
            },
          ],
          number: [
            {
              required: false,
              message: "请输入编号",
              trigger: "blur",
            },
          ],
          heji: [
            {
              required: true,
              message: "请输入合计金额",
              trigger: "blur",
            },
          ],
          fileCount: [
            {
              required: true,
              message: "请输入附件数量",
              trigger: "blur",
            },
          ],
        },
      };
    },
    props: {
      info: {
        type: Object,
      },
      modal: {
        type: Boolean,
        default: false,
      },
    },
    components: { MultiSearchUser },
    mounted() {
      this.uploadFileUrl = request.getUploadUrl("document");
      if (this.info && this.info.id > 0) {
        this.getData();
      } else {
        var flag = false;
        if (flag) {
          this.form = item;
        } else {
          var params = this.$route.params;
          if (params && params.outUser) {
            //从出差跳转过来
            this.form.applyUser = params.outUser;
            this.form.applyUserName = params.outUserName;
            this.form.duties = params.duties;
            this.form.time = this.$moment();
          } else if (params && params.status != null) {
            //从列表新增过来
            this.form = params;
          }
          this.form.time = this.$moment();
        }
      }
      this.getDic();
      this.getOrgTree();
    },
    watch: {
      form: {
        handler(newValue, oldValue) {
          if (newValue.id == null || newValue.id == 0) {
          }
          // localStorage.setItem("travelexpense", JSON.stringify(newValue));
        },
        deep: true,
      },
    },
    methods: {
      createnew() {
        this.form = { status: 0, items: [{}] };
        this.getOrgTree();
        // localStorage.removeItem("travelexpense");
      },
      caltotal() {
        var total = 0;
        if (this.form.ccjp) {
          total += this.form.ccjp;
        }
        if (this.form.hsbz) {
          total += this.form.hsbz;
        }
        if (this.form.gzf) {
          total += this.form.gzf;
        }
        if (this.form.zsf) {
          total += this.form.zsf;
        }
        if (this.form.snjt) {
          total += this.form.snjt;
        }
        if (this.form.qita) {
          total += this.form.qita;
        }

        if (total > 0) {
          this.form.heji = total;
        }
      },
      async getDic() {
        var d1r = await dictionaryItemApi.getItemByDictionaryName("职务");
        if (d1r.errorCode == this.$commons.RespCode.success) {
          this.zhiwu = d1r.data;
        }
      },
      addItem() {
        this.form.items.push({});
      },
      removeItem(index) {
        this.form.items.splice(index, 1);
      },
      gotoList() {
        this.$router.push({ path: "/business/travelexpenselist" });
      },
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },
      orgChanged(v, selectedOptions) {
        if (selectedOptions == null || selectedOptions.length == 0) {
          this.form.orgName = null;
          this.form.orgId = [];
        } else {
          this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
          this.form.orgId = v;
        }
        this.$forceUpdate();
      },
      selectUsersCallback(users) {
        this.selUser = [...users];
        this.form.otherApplyUser = users.map((item) => item.userName).join();
        this.form.otherApplyUserName = users.map((item) => item.realName).join();
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgs = res.data.tree;
            if (!this.info) {
              this.form.orgId = res.data.path;
              let tree = this.orgs;
              if (res.data.path) {
                for (var i = 0; i < res.data.path.length; ++i) {
                  var id = res.data.path[i];
                  var find = tree.filter((n) => n.id == id);
                  if (i == res.data.path.length - 1) {
                    this.form.orgName = find[0].name;
                  } else {
                    tree = find[0].children;
                  }
                }
              }
              this.form.applyUserName = res.data.realName;
              this.form.applyUser = localStorage.getItem(
                this.$commons.User.userName
              );
              this.getUserRolesByName(this.form.applyUser);
              this.$forceUpdate();
            }
          }
        });
      },
      getUserRolesByName(userName) {
        this.form.duties = "";
        roleApi.getUserRolesByName(userName).then((res) => {
          if (res.errorCode == "0000") {
            this.roleAll = res.data.filter((n) => n.name != "超级管理员");
            if (this.form.duties == "" || this.form.duties == null) {
              if (this.roleAll.length != null && this.roleAll.length > 0)
                this.form.duties = this.roleAll[0].name;
            }
          }
        });
      },
      getData() {
        this.loading = true;
        travelexpenseApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.orgId = res.data.orgPath;
            delete res.data.orgPath;
            if (res.data.userInfo) {
              this.selUser = JSON.parse(res.data.userInfo);
            }
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            if (res.data.files) {
              var d = JSON.parse(res.data.files);
              var files = [];
              for (var i = 1; i <= d.length; ++i) {
                var a = d[i - 1];
                files.push({
                  uid: i,
                  name: a.title,
                  status: "done",
                  response: a,
                  url: a.url,
                });
              }
              this.fileList = files;
            }
          }
        });
      },
      upLoadRemove() {
        return this.form.status == 0 || this.form.status == 3;
      },
      handleFileChange({ file, fileList }) {
        this.fileList = fileList;
        if (file.status == "uploading") {
        }
        if (file.status == "done") {
          fileList.forEach((item) => {
            if (item.response) {
              item.url = item.response.url;
            }
          });
          this.$message.success("上传成功");
        }
        if (file.status == "error") {
          this.$message.error("上传失败");
        }
      },
      handleSubmit(status) {
        this.$refs.loanbillform.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            formData.orgId = formData.orgId[formData.orgId.length - 1];
            this.loading = true;
            if (this.info) {
              formData.id = this.info.id;
            }
            delete formData.createTime;
            delete formData.creator;
            delete formData.creatorName;
            delete formData.auditRecords;
            formData.userInfo = JSON.stringify(this.selUser);
            let extensionSetting = {};
            if (formData.otherApplyUser) {
              extensionSetting["node_差旅费报销-其他报销人会签"] = {
                targetUsers: formData.otherApplyUser + "," + this.form.applyUser,
                count: formData.otherApplyUser.split(",").length + 1,
              };
            } else {
              extensionSetting["node_差旅费报销-其他报销人会签"] = {
                targetUsers: this.form.applyUser,
                count: 1,
              };
            }
            formData.extensionSetting = JSON.stringify(extensionSetting);
            if (this.fileList.length > 0) {
              var file = [];
              this.fileList.forEach((f) => {
                file.push({
                  title: f.response.title,
                  url: f.response.url,
                });
              });
              formData.files = JSON.stringify(file);
            }
            //添加
            travelexpenseApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  if (this.modal) {
                    this.$emit("callback", true);
                  } else {
                    this.gotoList();
                  }
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
      splitPrice(price, field) {
        let itemObj = {};
        price = price * 100;
        let size = 8;
        if (price.toString().length < 8) {
          size = 8 - price.toString().length;
          for (let j = 0; j < size; j++) {
            itemObj[field + (j + 1)] = "";
          }
        }
        for (let i = size; i < 8; i++) {
          itemObj[field + (i + 1)] = price.toString().substr(i - size, 1);
        }
        return itemObj;
      },
      showWps() {
        let templateId = "travelexpense";

        let innerData = { ...this.form };
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;
        innerData.time = this.$moment(innerData.time, "YYYY-MM-DD").format(
          "YYYY年MM月DD日"
        );
        // 将金额拆分为一位一位的数字
        if (innerData.ccjp) {
          innerData.ccjp = this.splitPrice(innerData.ccjp, "ccjp");
        }
        if (innerData.hsbz) {
          innerData.hsbz = this.splitPrice(innerData.hsbz, "hsbz");
        }
        if (innerData.gzf) {
          innerData.gzf = this.splitPrice(innerData.gzf, "gzf");
        }
        if (innerData.zsf) {
          innerData.zsf = this.splitPrice(innerData.zsf, "zsf");
        }
        if (innerData.snjt) {
          innerData.snjt = this.splitPrice(innerData.snjt, "snjt");
        }
        if (innerData.qita) {
          innerData.qita = this.splitPrice(innerData.qita, "qita");
        }
        if (innerData.heji) {
          innerData.hejiCN = this.$util.menoyToUppercase(innerData.heji);
          innerData.heji = this.splitPrice(innerData.heji, "heji");
        }
        let dataList = [];
        let descriptionArr = [];
        let remarkArr = [];
        if (innerData.items != null && innerData.items.length > 0) {
          let i = 0;
          innerData.items.forEach((a) => {
            let aTime = this.$moment(a.time, "YYYY-MM-DD");
            dataList.push({
              sort: ++i,
              ...a,
              time: {
                year: aTime.year(),
                month: aTime.month() + 1,
                day: aTime.date(),
              },
              price: this.splitPrice(a.price, "price"),
            });
            if (a.description && a.description.trim() != "") {
              descriptionArr.push(a.description);
            }
            if (a.remark && a.remark.trim() != "") {
              remarkArr.push(a.remark);
            }
          });
          delete innerData.items;
          innerData.list = dataList;
          innerData.descriptions = descriptionArr.join("；");
          innerData.remarks = remarkArr.join("；");
        }
        // 处理申请人图片
        if (innerData.applyUserPic != null && innerData.applyUserPic != "") {
          innerData.applyUserPic =
            "http://localhost:9000/" +
            innerData.applyUserPic.substring(
              innerData.applyUserPic.indexOf("uas")
            );
        }
        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (key.indexOf("QiTaBaoXiaoRen") != -1) {
            key = "taskKeyQiTaBaoXiaoRen";
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
          // innerData['taskKeyFuShuJi ']=null;
        });
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "差旅费报销",
            tempUrl: "writeNumberTemp",
          },
        });

        window.open(jump.href, "_blank");
      },
    },
  };
</script>
